import { FC, useEffect, useRef } from "react";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import UploadIconAnimated from "@/../public/assets/upload_animation.json";

interface UploadAnimationProps {
  isUploading: boolean;
  isUploadComplete: boolean;
}

const UPLOAD_ANIMATION_SEGMENT: [number, number] = [0, 122];
const CHECKMARK_ANIMATION_SEGMENT: [number, number] = [240, 275];

export const UploadAnimation: FC<UploadAnimationProps> = ({
  isUploading,
  isUploadComplete,
}) => {
  const lottieRef = useRef<LottieRefCurrentProps>(null);

  useEffect(() => {
    if (!lottieRef.current) return;

    if (isUploadComplete) {
      lottieRef.current.playSegments(CHECKMARK_ANIMATION_SEGMENT, true);
    } else if (isUploading) {
      lottieRef.current.playSegments(UPLOAD_ANIMATION_SEGMENT, true);
    } else {
      lottieRef.current.goToAndStop(0, true);
    }
  }, [isUploading, isUploadComplete]);

  return (
    <Lottie
      lottieRef={lottieRef}
      loop={isUploading && !isUploadComplete}
      animationData={UploadIconAnimated}
      autoplay={false}
      className="w-24 h-24"
    />
  );
};
