import { FC } from "react";
import { X } from "lucide-react";
import { Button } from "@/components/ui/button";

interface AudioDisplayProps {
  audioSrc: string;
  removeAudio: () => void;
}

export const AudioDisplay: FC<AudioDisplayProps> = ({
  audioSrc,
  removeAudio,
}) => {
  return (
    <div className="flex items-center border rounded-md overflow-hidden h-20">
      <div className="flex-grow audio-wrapper">
        <audio src={audioSrc} controls className="w-full">
          Your browser does not support the audio tag.
        </audio>
      </div>
      <Button
        onClick={removeAudio}
        variant="ghost"
        size="icon"
        className="flex-shrink-0 w-8 h-8 mr-2"
      >
        <X className="h-4 w-4" />
      </Button>
    </div>
  );
};
