export interface UploadFileProps {
  src: string;
  type: "video" | "audio";
  timestamp: number;
  accessToken: string;
}

export default async function uploadFile({
  src,
  type,
  timestamp,
  accessToken,
}: UploadFileProps) {
  const formData = new FormData();
  const blob = await fetch(src).then((r) => r.blob());
  const extension = type === "video" ? "mp4" : "mp3";
  const fileName = `${type}-input-${timestamp}.${extension}`;

  formData.append("file", blob, fileName);

  const uploadResponse = await fetch("/api/file-storage/upload", {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const uploadData = await uploadResponse.json();
  return uploadData.url;
}
