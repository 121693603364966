import { ModelFragment, GenerationFragment } from "@/generated/graphql";
import { UploadFileProps } from "@/lib/api/uploadFile";

export const handleSubmit = async ({
  videoSrc,
  audioSrc,
  model,
  isCompareMode,
  comparisonModel,
  generation,
  comparisonGeneration,
  options,
  accessToken,
  organization,
  setError,
  setIsGenerating,
  setIsUploading,
  setIsUploadComplete,
  setGeneration,
  setComparisonGeneration,
  uploadFile,
  generateRequest,
  fetchGeneration,
}: {
  videoSrc: string | null;
  audioSrc: string | null;
  model: ModelFragment | null;
  isCompareMode: boolean;
  comparisonModel: ModelFragment | null;
  generation: GenerationFragment | null;
  comparisonGeneration: GenerationFragment | null;
  options: any;
  accessToken: string | undefined;
  organization: { id: string } | null;
  setError: (error: string | null) => void;
  setIsGenerating: (isGenerating: boolean) => void;
  setIsUploading: (isUploading: boolean) => void;
  setIsUploadComplete: (isUploadComplete: boolean) => void;
  setGeneration: (generation: GenerationFragment | null) => void;
  setComparisonGeneration: (generation: GenerationFragment | null) => void;
  uploadFile: ({ ...props }: UploadFileProps) => Promise<string>;
  generateRequest: (
    modelName: string,
    input: any[],
    options: any,
    accessToken: string,
  ) => Promise<{ id: string }>;
  fetchGeneration: any;
}) => {
  if (videoSrc && audioSrc && model && (!isCompareMode || comparisonModel)) {
    if (generation) setGeneration(null);
    if (comparisonGeneration) setComparisonGeneration(null);
    try {
      setError(null);

      let input;
      if (generation) {
        input = generation.inputs;
      } else {
        setIsUploading(true);
        const timestamp = Math.floor(Date.now() / 1000);

        let videoUrl, audioUrl;
        try {
          videoUrl = await uploadFile({
            src: videoSrc,
            type: "video",
            timestamp,
            accessToken: accessToken!,
          });
          audioUrl = await uploadFile({
            src: audioSrc,
            type: "audio",
            timestamp,
            accessToken: accessToken!,
          });
        } catch (uploadError) {
          throw new Error("Failed to upload files. Please try again.");
        }

        input = [
          { type: "video", url: videoUrl },
          { type: "audio", url: audioUrl },
        ];

        setIsUploading(false);
        setIsUploadComplete(true);
      }

      console.log(`ABOUT TO SET IS GENERATING`);
      setIsGenerating(true);
      const requests = isCompareMode
        ? [
            generateRequest(model.name, input, options, accessToken!),
            generateRequest(
              comparisonModel!.name,
              input,
              options,
              accessToken!,
            ),
          ]
        : [generateRequest(model.name, input, options, accessToken!)];

      const data = await Promise.all(requests);

      const fetchedGenerations = await Promise.all(
        data.map(async ({ id }: { id: string }) => {
          const { data: fetchedGeneration } = await fetchGeneration({
            variables: {
              id,
              organizationId: organization?.id,
            },
          });
          return fetchedGeneration?.generations[0] || null;
        }),
      );

      if (fetchedGenerations[0]) {
        setGeneration(fetchedGenerations[0]);
      }

      if (isCompareMode && fetchedGenerations[1]) {
        setComparisonGeneration(fetchedGenerations[1]);
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      setError(
        error instanceof Error ? error.message : "An unknown error occurred",
      );

      setIsUploading(false);
      setIsUploadComplete(false);
      setIsGenerating(false);
      setGeneration(null);
      setComparisonGeneration(null);
    } finally {
      setIsUploading(false);
    }
  }
};
