import { ChangeEvent, useState } from "react";
import { Input } from "@/components/ui/input";
import { Slider } from "@/components/ui/slider";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { usePlayground } from "../../../../hooks/use-playground";

export function TemperatureSlider() {
  const { options, setOptions, isUploading, isGenerating } = usePlayground();
  const [isInputActive, setIsInputActive] = useState(false);

  const isDisabled = isUploading || isGenerating;

  const handleTemperatureChange = (value: number) => {
    const newValue = Math.min(Math.max(value, 0), 1);
    setOptions({ ...options, expressiveness: newValue });
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleTemperatureChange(Number(e.target.value));
  };

  return (
    <HoverCard>
      <HoverCardTrigger asChild>
        <div
          onMouseEnter={() => !isDisabled && setIsInputActive(true)}
          onMouseLeave={() => !isDisabled && setIsInputActive(false)}
          className={`flex flex-col gap-4 ${isDisabled ? "opacity-50" : ""}`}
        >
          <div className="flex items-center justify-between">
            <label className="text-sm font-bold">
              temperature / expressiveness
            </label>
            <Input
              type="number"
              min={0}
              max={1}
              step={0.01}
              value={options.expressiveness}
              onChange={handleInputChange}
              className={`w-20 h-8 text-right ${
                isInputActive && !isDisabled
                  ? "border-input"
                  : "border-transparent"
              }`}
              onFocus={() => !isDisabled && setIsInputActive(true)}
              onBlur={() => !isDisabled && setIsInputActive(false)}
              disabled={isDisabled}
            />
          </div>
          <Slider
            min={0}
            max={1}
            step={0.01}
            value={[options.expressiveness]}
            onValueChange={(value) =>
              !isDisabled && handleTemperatureChange(value[0])
            }
            className="w-full"
            disabled={isDisabled}
          />
        </div>
      </HoverCardTrigger>
      <HoverCardContent side="left" className="w-80">
        <div className="space-y-2">
          <h4 className="font-semibold">Temperature</h4>
          <p className="text-sm">
            Controls randomness: Lowering results in less random completions. As
            the temperature approaches zero, the model will become deterministic
            and repetitive.
          </p>
        </div>
      </HoverCardContent>
    </HoverCard>
  );
}
