import { useEffect } from "react";
import { usePlaygroundState } from "./use-playground-state";
import { usePlaygroundQueries } from "./use-playground-queries";
import { isEqual } from "lodash";

export function usePlaygroundEffects(
  state: ReturnType<typeof usePlaygroundState>,
  queries: ReturnType<typeof usePlaygroundQueries>,
) {
  // updates model and options when an active preset is selected
  useEffect(() => {
    if (state.activePreset) {
      state.setModel(state.activePreset.model);
      state.setOptions(state.activePreset.options);
    }
  }, [state, state.activePreset]);

  // sets models and default model when models data is available
  // model defaults to first model in list
  // comparisonModel defaults to second model in list
  useEffect(() => {
    if (queries.modelsData?.models && queries.modelsData.models.length > 0) {
      state.setModels(queries.modelsData.models);
      state.setModel(
        (prevModel) => prevModel || queries.modelsData?.models[0] || null,
      );
      state.setComparisonModel(
        (prevModel) => prevModel || queries.modelsData?.models[1] || null,
      );
    }
  }, [queries.modelsData, queries.modelsError, state]);

  // disables the playground when required inputs are missing
  useEffect(() => {
    state.setIsDisabled(!state.videoSrc || !state.audioSrc || !state.model);
  }, [state.videoSrc, state.audioSrc, state.model, state]);

  // updates generation data when available
  useEffect(() => {
    if (queries.generationData?.generation)
      state.setGeneration(queries.generationData.generation);
  }, [queries.generationData, state]);

  // updates comparison generation data when available
  useEffect(() => {
    if (queries.comparisonGenerationData?.generation)
      state.setComparisonGeneration(
        queries.comparisonGenerationData.generation,
      );
  }, [queries.comparisonGenerationData, state]);

  useEffect(() => {
    if (!state.generation || !state.model) {
      state.setIsGenerationMatching(true);
      return;
    }

    const modelNameMatches = state.generation.modelName === state.model.name;
    const optionsMatch = isEqual(state.generation.options, state.options);

    state.setIsGenerationMatching(modelNameMatches && optionsMatch);
  }, [state, state.generation, state.model, state.options]);

  return {};
}
