import { TemperatureSlider } from "./components/temperature-slider";
import { PaddingInput } from "./components/padding-input";
import { QualitySelector } from "./components/quality-selector";
import { OutputFormatSelector } from "./components/output-format-selector";

export default function PropertiesMenu() {
  return (
    <div className="space-y-4 mt-4">
      <PaddingInput />
      <TemperatureSlider />
      <OutputFormatSelector />
      <QualitySelector />
    </div>
  );
}
