import { FC } from "react";
import { Button } from "@/components/ui/button";
import Image from "next/image";
import { usePlayground } from "../../hooks/use-playground";

const CompareButton: FC = () => {
  const { isCompareMode, setIsCompareMode, isUploading, isGenerating } =
    usePlayground();

  const isDisabled = isUploading || isGenerating;

  return (
    <Button
      variant="outline"
      onClick={() => setIsCompareMode(!isCompareMode)}
      className="text-white hover:bg-black/80 disabled:opacity-50 disabled:cursor-not-allowed"
      disabled={isDisabled}
    >
      <span className="mr-2">compare</span>
      <Image
        className="color-white"
        src="/icons/compare.svg"
        alt="Compare"
        width={20}
        height={20}
      />
    </Button>
  );
};

export default CompareButton;
