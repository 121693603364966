"use client";

import { useState, useRef, FC } from "react";
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui";
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui";
import { Label } from "@/components/ui";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui";

import { ModelFragment } from "@/generated/graphql";
import { useMutationObserver } from "@/hooks/use-mutation-observer";
import { usePlayground } from "../../hooks/use-playground";

interface ModelSelectorProps {
  models: ModelFragment[];
  model: ModelFragment | null;
  setModel: (model: ModelFragment | null) => void;
}

const ModelSelector: FC<ModelSelectorProps> = ({ models, model, setModel }) => {
  const { isUploading, isGenerating } = usePlayground();
  const [open, setOpen] = useState(false);
  const selectedModel = model || (models.length > 0 ? models[0] : null);
  const [peekedModel, setPeekedModel] = useState<ModelFragment | null>(
    selectedModel,
  );

  const isDisabled = isUploading || isGenerating;

  if (models.length === 0) {
    return (
      <div className="grid gap-2">
        <Label htmlFor="model">Model</Label>
        <Button variant="secondary" disabled className="w-full justify-between">
          No models available
        </Button>
      </div>
    );
  }

  return (
    <div className="grid gap-2">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            aria-label="Select a model"
            className="w-44 justify-between truncate"
            disabled={isDisabled}
          >
            <div className="flex justify-start truncate w-full">
              {selectedModel ? selectedModel.name : "Select..."}
            </div>
            <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent align="start" className="w-44 p-0">
          <HoverCard>
            <HoverCardContent
              side="left"
              align="start"
              forceMount
              className="absolute left-[184px]"
            >
              {peekedModel && (
                <div className="grid gap-2">
                  <h4 className="font-medium leading-none">
                    {peekedModel.name}
                  </h4>
                  <div className="text-sm text-muted-foreground">
                    {peekedModel.type} - v{peekedModel.version}
                  </div>
                  <div className="text-sm text-muted-foreground">
                    {peekedModel.description}
                  </div>
                </div>
              )}
            </HoverCardContent>
            <Command loop>
              <CommandList className="h-[var(--cmdk-list-height)] max-h-[400px]">
                <CommandInput placeholder="Search..." />
                <CommandEmpty>No Models found.</CommandEmpty>
                <HoverCardTrigger />

                {models.map((model) => (
                  <ModelItem
                    key={model.id}
                    model={model}
                    isSelected={selectedModel?.id === model.id}
                    onPeek={(model) => setPeekedModel(model)}
                    onSelect={() => {
                      console.log(`selected - `, model);
                      setModel(model);
                      setOpen(false);
                    }}
                  />
                ))}
              </CommandList>
            </Command>
          </HoverCard>
        </PopoverContent>
      </Popover>
    </div>
  );
};

interface ModelItemProps {
  model: ModelFragment;
  isSelected: boolean;
  onSelect: () => void;
  onPeek: (model: ModelFragment) => void;
}

function ModelItem({ model, isSelected, onSelect, onPeek }: ModelItemProps) {
  const ref = useRef<HTMLDivElement>(null);

  useMutationObserver(ref, (mutations) => {
    mutations.forEach((mutation) => {
      if (
        mutation.type === "attributes" &&
        mutation.attributeName === "aria-selected" &&
        ref.current?.getAttribute("aria-selected") === "true"
      ) {
        onPeek(model);
      }
    });
  });

  return (
    <CommandItem
      key={model.id}
      onSelect={onSelect}
      ref={ref}
      className="data-[selected=true]:bg-primary data-[selected=true]:text-primary-foreground"
    >
      {model.name}
      <CheckIcon
        className={cn(
          "ml-auto h-4 w-4",
          isSelected ? "opacity-100" : "opacity-0",
        )}
      />
    </CommandItem>
  );
}

export default ModelSelector;
