"use client";

import { FC, useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { usePlayground } from "../../hooks/use-playground";
import { RefreshCcw } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

const ClearButton: FC = () => {
  const [open, setOpen] = useState(false);
  const {
    videoSrc,
    audioSrc,
    generation,
    setIsUploading,
    setIsGenerating,
    setVideoSrc,
    setAudioSrc,
    setGeneration,
    setIsUploadComplete,
    isUploading,
    isGenerating,
  } = usePlayground();

  const isDisabled = (!videoSrc && !audioSrc && !generation) || isUploading;

  const handleClear = () => {
    setIsUploadComplete(false);
    setVideoSrc(null);
    setAudioSrc(null);
    setGeneration(null);
    setIsUploading(false);
    setIsGenerating(false);
    setOpen(false);
  };

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <span style={{ display: "inline-block" }}>
            <Button
              variant="outline"
              size="icon"
              disabled={isDisabled}
              onClick={() => !isDisabled && setOpen(true)}
            >
              <RefreshCcw size={14} />
            </Button>
          </span>
        </TooltipTrigger>
        <TooltipContent>
          {isDisabled ? (
            <p>
              {isUploading || isGenerating
                ? "Cannot clear while uploading or generating"
                : "Clear the playground - You currently don't have anything to clear"}
            </p>
          ) : (
            <p>Clear the playground</p>
          )}
        </TooltipContent>
      </Tooltip>

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Are you absolutely sure?</DialogTitle>
            <DialogDescription>
              this is going to delete the uploaded videos... and you will have
              to start from scratch. note: you can save your presets before
              doing this{" "}
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button variant="destructive" onClick={handleClear}>
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </TooltipProvider>
  );
};

export default ClearButton;
