import { useState, useEffect } from "react";
import {
  GenerationPresetFragment,
  useGetOrganizationGenerationPresetsQuery,
  useCreateGenerationPresetMutation,
  useUpdateGenerationPresetMutation,
} from "@/generated/graphql";
import { useUserDetailsContext } from "@/providers/user-details-provider";

export const useGenerationPresets = (organizationId: string) => {
  const { userDetails } = useUserDetailsContext();
  const { data, loading, error } = useGetOrganizationGenerationPresetsQuery({
    variables: { organizationId },
  });
  const [generationPresets, setGenerationPresets] = useState<
    GenerationPresetFragment[]
  >([]);

  const [createGenerationPreset] = useCreateGenerationPresetMutation();
  const [updateGenerationPreset] = useUpdateGenerationPresetMutation();

  useEffect(() => {
    if (data && data.generationPresets) {
      setGenerationPresets(data.generationPresets);
    }
  }, [data]);

  const createPreset = async (
    name: string,
    description: string | null,
    modelName: string,
    options: any,
  ) => {
    const result = await createGenerationPreset({
      variables: {
        userId: userDetails.id,
        organizationId,
        name,
        description,
        modelName,
        options,
      },
    });
    if (result.data?.generationPreset) {
      setGenerationPresets([
        ...generationPresets,
        result.data.generationPreset,
      ]);
    }
    return result.data?.generationPreset;
  };

  const updatePreset = async (
    id: string,
    name: string,
    description: string | null,
    modelName: string,
    options: any,
  ) => {
    const result = await updateGenerationPreset({
      variables: {
        id,
        set: {
          name,
          description,
          modelName,
          options,
        },
      },
    });
    if (result.data?.generationPreset) {
      setGenerationPresets(
        generationPresets.map((preset) =>
          preset.id === id ? result.data!.generationPreset! : preset,
        ),
      );
    }
    return result.data?.generationPreset;
  };

  return {
    generationPresets,
    createPreset,
    updatePreset,
    loading,
    error,
  };
};
