import { FC } from "react";
import Image from "next/image";

export const PlaygroundDivider: FC = () => {
  return (
    <div className="flex items-center">
      <div className="flex-grow h-px bg-secondary"></div>
      <div className="flex-shrink-0">
        <Image src="/icons/sync-mouth.svg" width={30} height={30} alt="Sync" />
      </div>
      <div className="flex-grow h-px bg-secondary"></div>
    </div>
  );
};
