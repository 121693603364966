import { FC } from "react";
import { X } from "lucide-react";

import { usePlayground } from "../../hooks/use-playground";
import { Button } from "@/components/ui/button";
import { ModelFragment } from "@/generated/graphql";
import ModelSelector from "./model-selector";
import VideoInput from "./inputs/video-input";
import { VideoOutput } from "./outputs/video-output";

interface ModelAndVideoProps {
  models: ModelFragment[];
  model: ModelFragment | null;
  setModel: (model: ModelFragment | null) => void;
}

export const ModelAndVideo: FC<ModelAndVideoProps> = ({
  models,
  model,
  setModel,
}) => {
  const { setIsCompareMode, videoSrc, generation } = usePlayground();

  const showOutputs = generation;

  return (
    <div className="flex flex-col w-full gap-2">
      <div className="flex justify-between items-center">
        <ModelSelector models={models} model={model} setModel={setModel} />
        <Button
          variant="outline"
          size="icon"
          onClick={() => setIsCompareMode(false)}
          aria-label="Close compare mode"
        >
          <X className="h-4 w-4" />
        </Button>
      </div>
      {showOutputs ? <VideoOutput /> : <VideoInput showOptions={!videoSrc} />}
    </div>
  );
};
