import { FC, useEffect } from "react";
import {
  Button,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider,
} from "@/components/ui";
import { usePlayground } from "../../hooks/use-playground";

interface GenerateButtonProps {
  label?: string;
}
const GenerateButton: FC<GenerateButtonProps> = ({ label = "Generate" }) => {
  const {
    isUploading,
    isGenerating,
    handleSubmit,
    isDisabled,
    isGenerationMatching,
    generation,
  } = usePlayground();

  const submitDisabled =
    isDisabled ||
    isUploading ||
    isGenerating ||
    (!!generation && isGenerationMatching);

  console.log(`isGenerationMatching: `, isGenerationMatching);

  useEffect(() => {
    console.log(`isGenerating: `, isGenerating);
  }, [isGenerating]);

  const getButtonContent = () => {
    if (isUploading) return "Uploading...";
    if (isGenerating) return "Generating...";
    return label;
  };

  const getTooltipContent = () => {
    if (isDisabled)
      return "Please upload both video and audio before submitting";
    if (isUploading) return "Uploading files";
    if (isGenerating) return "Generating video";
    if (!!generation && isGenerationMatching)
      return "You must update the model or properties to re-generate";
    return "Click to submit";
  };

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <span style={{ display: "inline-block" }}>
            <Button
              variant={"default"}
              onClick={handleSubmit}
              disabled={submitDisabled}
            >
              {getButtonContent()}
            </Button>
          </span>
        </TooltipTrigger>
        <TooltipContent>
          <p>{getTooltipContent()}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default GenerateButton;
