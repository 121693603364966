export default async function generateRequest(
  model: string,
  input: any,
  options: any,
  accessToken: string,
) {
  const response = await fetch("/api/generate", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      model,
      input,
      options,
      webhookUrl: `${process.env.NEXT_PUBLIC_SITE_URL}/api/webhook/generation`,
    }),
  });

  if (!response.ok) {
    throw new Error("API request failed");
  }

  return response.json();
}
