export const phpSnippet = `<?php

$curl = curl_init();

curl_setopt_array($curl, [
  CURLOPT_URL => "https://api.sync.so/v2/generate",
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => "",
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 30,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => "POST",
  CURLOPT_POSTFIELDS => json_encode([
    "model" => "\${modelName}",
    "input" => [
      [
        "type" => "video",
        "url" => "https://example.com/your-video.mp4"
      ],
      [
        "type" => "audio",
        "url" => "https://example.com/your-audio.mp3"
      ]
    ],
    "options" => \${optionsString},
    "webhookUrl" => "https://your-server.com/webhook"
  ]),
  CURLOPT_HTTPHEADER => [
    "Content-Type: application/json",
    "x-api-key: <api-key>"
  ],
]);

$response = curl_exec($curl);
$err = curl_error($curl);

curl_close($curl);

if ($err) {
  echo "cURL Error #:" . $err;
} else {
  echo $response;
}`;
