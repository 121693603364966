import { FC, MutableRefObject } from "react";
import { X } from "lucide-react";
import { Button } from "@/components/ui/button";

interface VideoDisplayProps {
  videoSrc: string;
  videoRef: MutableRefObject<HTMLVideoElement | null>;
  removeVideo: () => void;
}

export const VideoDisplay: FC<VideoDisplayProps> = ({
  videoSrc,
  videoRef,
  removeVideo,
}) => {
  return (
    <div className="relative aspect-[7/3] bg-background rounded">
      <Button
        onClick={removeVideo}
        variant="ghost"
        size="icon"
        className="absolute top-2 right-2 z-10"
      >
        <X className="h-4 w-4" />
      </Button>
      <video
        ref={videoRef}
        src={videoSrc}
        controls
        className="w-full h-full object-contain rounded-lg"
      >
        Your browser does not support the video tag.
      </video>
    </div>
  );
};
