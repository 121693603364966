export const javascriptSnippet = `const options = {
  method: 'POST',
  headers: {'x-api-key': '<api-key>', 'Content-Type': 'application/json'},
  body: JSON.stringify({
    model: "\${modelName}",
    input: [
      {
        type: "video",
        url: "https://example.com/your-video.mp4"
      },
      {
        type: "audio",
        url: "https://example.com/your-audio.mp3"
      }
    ],
    options: \${optionsString},
    webhookUrl: "https://your-server.com/webhook"
  })
};

fetch('https://api.sync.so/v2/generate', options)
  .then(response => response.json())
  .then(response => console.log(response))
  .catch(err => console.error(err));`;
