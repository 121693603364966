import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Link, Upload, X } from "lucide-react";

interface HostedUrlInputProps {
  onSubmit: (url: string) => void;
}

export const HostedUrlInput: React.FC<HostedUrlInputProps> = ({ onSubmit }) => {
  const [showInput, setShowInput] = useState(false);
  const [hostedUrl, setHostedUrl] = useState("");

  const handleSubmit = () => {
    onSubmit(hostedUrl);
    setShowInput(false);
    setHostedUrl("");
  };

  if (showInput) {
    return (
      <div className="flex w-full max-w-[320px] lg:w-[320px] relative">
        <Input
          type="text"
          placeholder="enter url"
          value={hostedUrl}
          onChange={(e) => setHostedUrl(e.target.value)}
          className="h-9 pr-[70px]"
        />
        <div className="absolute right-2 top-1/2 -translate-y-1/2 flex gap-2">
          <Button
            variant="secondary"
            size="icon"
            onClick={handleSubmit}
            className="h-6 w-6"
          >
            <Upload className="p-1.5" />
          </Button>
          <Button
            variant="secondary"
            size="icon"
            onClick={() => setShowInput(false)}
            className="h-6 w-6"
          >
            <X className="p-1.5" />
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Button variant="secondary" size="sm" onClick={() => setShowInput(true)}>
      <div className="flex gap-2 items-center">
        Hosted URL
        <Link className="h-4 w-4" />
      </div>
    </Button>
  );
};
