export const javaSnippet = `import java.net.http.HttpClient;
import java.net.http.HttpRequest;
import java.net.http.HttpResponse;
import java.net.URI;

public class SyncAPIExample {
    public static void main(String[] args) throws Exception {
        HttpRequest request = HttpRequest.newBuilder()
            .uri(URI.create("https://api.sync.so/v2/generate"))
            .header("x-api-key", "<api-key>")
            .header("Content-Type", "application/json")
            .POST(HttpRequest.BodyPublishers.ofString(String.format("""
                {
                  "model": "%s",
                  "input": [
                    {
                      "type": "video",
                      "url": "https://example.com/your-video.mp4"
                    },
                    {
                      "type": "audio",
                      "url": "https://example.com/your-audio.m3"
                    }
                  ],
                  "options": %s,
                  "webhookUrl": "https://your-server.com/webhook"
                }
                """, "\${modelName}", \${optionsString})))
            .build();

        HttpResponse<String> response = HttpClient.newHttpClient().send(request, HttpResponse.BodyHandlers.ofString());
        System.out.println(response.body());
    }
}`;
