import { useAccessToken } from "@nhost/nextjs";
import { useUserDetailsContext } from "@/providers/user-details-provider";
import { useOrganization } from "@/hooks/use-organization";
import { useGenerationPresets } from "./use-generation-presets";
import {
  useGetModelsQuery,
  useGenerationUpdatedSubscription,
  useGetGenerationByIdLazyQuery,
} from "@/generated/graphql";
import { usePlaygroundState } from "./use-playground-state";

export function usePlaygroundQueries(
  state: ReturnType<typeof usePlaygroundState>,
) {
  // Authentication and user context
  const accessToken = useAccessToken();
  const { userDetails } = useUserDetailsContext();
  const { organization } = useOrganization();

  // Query for fetching available models
  const {
    data: modelsData,
    loading: modelsLoading,
    error: modelsError,
  } = useGetModelsQuery();

  // Subscription for real-time updates on the main generation
  const { data: generationData } = useGenerationUpdatedSubscription({
    variables: { id: state.generation?.id },
    skip: !state.generation,
  });

  // Subscription for real-time updates on the comparison generation
  const { data: comparisonGenerationData } = useGenerationUpdatedSubscription({
    variables: { id: state.comparisonGeneration?.id },
    skip: !state.comparisonGeneration,
  });

  // Lazy query for fetching a specific generation by ID
  const [fetchGeneration] = useGetGenerationByIdLazyQuery();

  // Hook for managing generation presets
  const {
    generationPresets,
    createPreset,
    updatePreset,
    loading: presetsLoading,
    error: presetsError,
  } = useGenerationPresets(organization?.id || "");

  // Return all queried data and functions for use in the playground
  return {
    accessToken,
    userDetails,
    organization,
    modelsData,
    modelsLoading,
    modelsError,
    generationData,
    comparisonGenerationData,
    fetchGeneration,
    generationPresets,
    createPreset,
    updatePreset,
    presetsLoading,
    presetsError,
  };
}
