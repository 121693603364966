import { usePlayground } from "./use-playground";
import { ChangeEvent, useRef, useState } from "react";

// Custom hook for audio handling
export const useAudioHandler = () => {
  const { audioSrc, setAudioSrc } = usePlayground();
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const streamRef = useRef<MediaStream | null>(null);

  const handleAudioUpload = (event: ChangeEvent<HTMLInputElement>) => {
    console.log(`handleAudioUpload`);
    const file = event.target.files?.[0];
    if (file) {
      const newAudioSrc = URL.createObjectURL(file);
      setAudioSrc(newAudioSrc);
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      streamRef.current = stream;
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: "audio/mp4",
      });

      const chunks: Blob[] = [];

      mediaRecorderRef.current.ondataavailable = (e) => chunks.push(e.data);
      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunks, { type: "audio/webm" });
        const newAudioSrc = URL.createObjectURL(blob);
        setAudioSrc(newAudioSrc);
      };

      setIsRecording(true);
      mediaRecorderRef.current.start();
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);

      // Stop all tracks of the stream
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
    }
  };

  return {
    audioSrc,
    isRecording,
    handleAudioUpload,
    startRecording,
    stopRecording,
    audioRef,
  };
};
