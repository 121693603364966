"use client";

import { FC, useState, useEffect, useRef } from "react";
import { usePlayground } from "../../../../hooks/use-playground";
import { useVideoHandler } from "../../../../hooks/use-video-handler";
import { UploadAnimation } from "./components/upload-animation";
import { VideoDisplay } from "./components/video-display";

import { RecordingPreview } from "./components/recording-preview";
import { HostedUrlInput } from "../hosted-url-input";
import { RecordButton } from "../record-button";

const DROP_OR_RECORD = "drop video file or record";
const ACCEPTED_FORMATS = ".mp4, .mov and .avi file formats accepted";

interface VideoInputProps {
  showOptions: boolean;
}

export const VideoInput: FC<VideoInputProps> = ({ showOptions }) => {
  const { isUploading, isUploadComplete, videoSrc, setVideoSrc } =
    usePlayground();
  const {
    videoRef,
    isRecording,
    startRecording,
    stopRecording,
    previewStream,
    handleVideoUpload,
  } = useVideoHandler();

  const [showAnimation, setShowAnimation] = useState(false);
  const [isPreparingToRecord, setIsPreparingToRecord] = useState(false);
  const localVideoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (localVideoRef.current && previewStream) {
      localVideoRef.current.srcObject = previewStream;
    }
  }, [previewStream]);

  useEffect(() => {
    setShowAnimation(isUploading || isUploadComplete);
    if (!isUploading && isUploadComplete) {
      const timer = setTimeout(() => setShowAnimation(false), 1500);
      return () => clearTimeout(timer);
    }
  }, [isUploading, isUploadComplete]);

  useEffect(() => {
    console.log(`showAnimation: `, showAnimation);
  }, [showAnimation]);

  const handleHostedUrlSubmit = (url: string) => setVideoSrc(url);
  const removeVideo = () => setVideoSrc("");

  const handleStartRecording = async () => {
    setIsPreparingToRecord(true);
    await startRecording();
    setIsPreparingToRecord(false);
  };

  const renderContent = () => {
    if (videoSrc && !showAnimation) {
      return (
        <VideoDisplay
          videoSrc={videoSrc}
          videoRef={videoRef}
          removeVideo={removeVideo}
        />
      );
    }
    if (showAnimation) {
      return (
        <div className="flex aspect-[7/3] justify-center items-center bg-background border border-dashed rounded">
          <UploadAnimation
            isUploading={isUploading}
            isUploadComplete={isUploadComplete}
          />
        </div>
      );
    }
    return (
      <div className="relative flex flex-col aspect-[7/3] bg-background border border-dashed rounded">
        <div className="absolute inset-0">
          <RecordingPreview
            localVideoRef={localVideoRef}
            isRecording={isRecording}
            isPreparingToRecord={isPreparingToRecord}
            isUploading={isUploading}
            handleVideoUpload={handleVideoUpload}
          />
        </div>
        {showOptions && !showAnimation && (
          <div className="absolute bottom-4 left-4 right-4 flex justify-between gap-4 z-10">
            <VideoInputMessage />
            <div className="flex gap-2 w-full justify-end">
              <HostedUrlInput onSubmit={handleHostedUrlSubmit} />
              <RecordButton
                isRecording={isRecording}
                isPreparingToRecord={isPreparingToRecord}
                onStartRecording={handleStartRecording}
                onStopRecording={stopRecording}
                mediaType="video"
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="relative">
      <div className="flex-grow">{renderContent()}</div>
    </div>
  );
};

const VideoInputMessage = () => {
  return (
    <div className="flex flex-col w-fit text-left">
      <p className="text-sm font-semibold whitespace-nowrap">
        {DROP_OR_RECORD}
      </p>
      <p className="text-xs text-gray-400 whitespace-nowrap">
        {ACCEPTED_FORMATS}
      </p>
    </div>
  );
};

export default VideoInput;
