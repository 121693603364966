"use client";

import { FC } from "react";
import { usePlayground } from "../../../hooks/use-playground";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

const VideoPlayer: FC<{ src: string }> = ({ src }) => (
  <div className="w-full h-full flex items-center justify-center">
    <div className="aspect-video w-full max-h-full">
      <video
        src={src}
        controls
        className="w-full h-full object-contain rounded-lg"
      />
    </div>
  </div>
);

const LoadingState: FC<{ status: string }> = ({ status }) => (
  <div className="flex flex-col items-center justify-center w-full h-full space-y-2 bg-black text-white">
    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-300"></div>
    <div>Generating...</div>
    <div>{status}</div>
  </div>
);

const ErrorState: FC<{ error: string }> = ({ error }) => (
  <div className="flex flex-col items-center justify-center w-full h-full space-y-2 text-red-600">
    <div>Generation Failed</div>
    <div>{error || "An error occurred during generation."}</div>
  </div>
);

const GeneratedContent: FC<{ generation: any }> = ({ generation }) => {
  const { setIsGenerating } = usePlayground();

  if (generation.status === "COMPLETED" && generation.outputMediaUrl) {
    setIsGenerating(false);
    return <VideoPlayer src={generation.outputMediaUrl} />;
  } else if (generation.status === "FAILED") {
    setIsGenerating(false);
    return <ErrorState error={JSON.stringify(generation.potentialError)} />;
  }
  return <LoadingState status={generation.status} />;
};

export const VideoOutput: FC = () => {
  const { generation } = usePlayground();

  if (!generation) return null;

  const videoInput = generation.inputs.find(
    (input: { type: string; url: string }) => input.type === "video",
  );

  return (
    <div className="w-full aspect-[7/3] relative bg-background rounded">
      <Tabs defaultValue="generated" className="w-full h-full">
        <TabsList className="absolute top-2 left-2 z-10 bg-secondary rounded-lg">
          <TabsTrigger value="source">Source</TabsTrigger>
          <TabsTrigger value="generated">Generated</TabsTrigger>
        </TabsList>

        <TabsContent value="source" className="w-full h-full">
          {videoInput && <VideoPlayer src={videoInput.url} />}
        </TabsContent>

        <TabsContent value="generated" className="w-full h-full">
          <GeneratedContent generation={generation} />
        </TabsContent>
      </Tabs>
    </div>
  );
};
