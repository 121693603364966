import { FC } from "react";

import { usePlayground } from "../../hooks/use-playground";
import AudioInput from "./inputs/audio-input";
import GenerateButton from "./generate-button";
import { PlaygroundDivider } from "./playground-divider";
import ClearButton from "./clear-button";
import { ModelAndVideo } from "./model-and-video";

export const PlaygroundCompareForm: FC = () => {
  const { models, model, setModel, comparisonModel, setComparisonModel } =
    usePlayground();
  return (
    <div className="flex flex-col w-full gap-2">
      <div className="flex flex-col lg:flex-row w-full gap-2">
        <ModelAndVideo models={models} model={model} setModel={setModel} />
        <ModelAndVideo
          models={models}
          model={comparisonModel}
          setModel={setComparisonModel}
        />
      </div>
      <PlaygroundDivider />
      <AudioInput />
      <div className="flex py-2 gap-4 justify-end">
        <ClearButton />
        <GenerateButton />
      </div>
    </div>
  );
};
