import { useState } from "react";
import { DEFAULT_OPTIONS } from "@/constants/playground-constants";
import {
  ModelFragment,
  GenerationFragment,
  GenerationPresetFragment,
} from "@/generated/graphql";

// Custom hook to manage the state of the playground component
export function usePlaygroundState() {
  // Media sources
  const [videoSrc, setVideoSrc] = useState<string | null>(null);
  const [audioSrc, setAudioSrc] = useState<string | null>(null);

  // Selected models
  const [model, setModel] = useState<ModelFragment | null>(null);
  const [comparisonModel, setComparisonModel] = useState<ModelFragment | null>(
    null,
  );

  // Generation options and presets
  const [options, setOptions] = useState(DEFAULT_OPTIONS);
  const [activePreset, setActivePreset] =
    useState<GenerationPresetFragment | null>(null);

  // Error handling
  const [error, setError] = useState<string | null>(null);

  // UI state
  // TODO: Update this to use one state object
  const [isDisabled, setIsDisabled] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isCompareMode, setIsCompareMode] = useState(false);

  // Generation results
  const [generation, setGeneration] = useState<GenerationFragment | null>(null);
  const [comparisonGeneration, setComparisonGeneration] =
    useState<GenerationFragment | null>(null);

  // Available models
  const [models, setModels] = useState<ModelFragment[]>([]);

  // Check if generation matches current model and options
  const [isGenerationMatching, setIsGenerationMatching] = useState(true);

  // Return all state variables and their setters
  return {
    videoSrc,
    setVideoSrc,
    audioSrc,
    setAudioSrc,
    model,
    setModel,
    comparisonModel,
    setComparisonModel,
    options,
    setOptions,
    activePreset,
    setActivePreset,
    error,
    setError,
    isDisabled,
    setIsDisabled,
    isUploading,
    setIsUploading,
    isUploadComplete,
    setIsUploadComplete,
    isGenerating,
    setIsGenerating,
    isCompareMode,
    setIsCompareMode,
    generation,
    setGeneration,
    comparisonGeneration,
    setComparisonGeneration,
    models,
    setModels,
    isGenerationMatching,
    setIsGenerationMatching,
  };
}
