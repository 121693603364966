export const curlSnippet = `curl --request POST \\
  --url https://api.sync.so/v2/generate \\
  --header 'Content-Type: application/json' \\
  --header 'x-api-key: <api-key>' \\
  --data '{
  "model": "\${modelName}",
  "input": [
    {
      "type": "video",
      "url": "https://example.com/your-video.mp4"
    },
    {
      "type": "audio",
      "url": "https://example.com/your-audio.mp3"
    }
  ],
  "options": \${optionsString},
  "webhookUrl": "https://your-server.com/webhook"
}'`;
