"use client";

import { useState } from "react";
import { Button } from "@/components/ui";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui";
import Image from "next/image";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import js from "react-syntax-highlighter/dist/esm/languages/hljs/javascript";
import python from "react-syntax-highlighter/dist/esm/languages/hljs/python";
import bash from "react-syntax-highlighter/dist/esm/languages/hljs/bash";
import php from "react-syntax-highlighter/dist/esm/languages/hljs/php";
import go from "react-syntax-highlighter/dist/esm/languages/hljs/go";
import java from "react-syntax-highlighter/dist/esm/languages/hljs/java";
import { vs2015 } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { usePlayground } from "../../../hooks/use-playground";

import { curlSnippet } from "./data/snippets/curl";
import { pythonSnippet } from "./data/snippets/python";
import { javascriptSnippet } from "./data/snippets/javascript";
import { phpSnippet } from "./data/snippets/php";
import { goSnippet } from "./data/snippets/go";
import { javaSnippet } from "./data/snippets/java";

SyntaxHighlighter.registerLanguage("javascript", js);
SyntaxHighlighter.registerLanguage("python", python);
SyntaxHighlighter.registerLanguage("bash", bash);
SyntaxHighlighter.registerLanguage("php", php);
SyntaxHighlighter.registerLanguage("go", go);
SyntaxHighlighter.registerLanguage("java", java);

const languages = ["cURL", "Python", "JavaScript", "PHP", "Go", "Java"];

const getCodeSnippet = (
  language: string,
  modelName: string,
  optionsString: string,
) => {
  const snippets: { [key: string]: string } = {
    cURL: curlSnippet,
    Python: pythonSnippet,
    JavaScript: javascriptSnippet,
    PHP: phpSnippet,
    Go: goSnippet,
    Java: javaSnippet,
  };

  const snippet = snippets[language] || "";
  return snippet
    .replace("${modelName}", modelName)
    .replace("${optionsString}", optionsString);
};

export default function ViewCodeButton() {
  const [selectedLanguage, setSelectedLanguage] = useState("cURL");
  const { model, options } = usePlayground();

  const modelName = model?.name || "sync-1.7.1-beta";
  const optionsString = JSON.stringify(options);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          variant="secondary"
          rightIcon={
            <Image
              className="color-white"
              src="/icons/code.svg"
              alt="Code icon"
              width={18}
              height={16}
            />
          }
        >
          View code
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[725px]">
        <DialogHeader>
          <DialogTitle>View code</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4">
          <div className="flex flex-wrap gap-2">
            {languages.map((lang) => (
              <Button
                key={lang}
                onClick={() => setSelectedLanguage(lang)}
                variant={selectedLanguage === lang ? "default" : "outline"}
                size="sm"
              >
                {lang}
              </Button>
            ))}
          </div>
          <div className="rounded-md bg-[#1E1E1E] p-4 max-h-[400px] overflow-auto">
            <SyntaxHighlighter
              language={
                selectedLanguage === "cURL"
                  ? "bash"
                  : selectedLanguage.toLowerCase()
              }
              style={vs2015}
              customStyle={{ background: "transparent" }}
              wrapLongLines={false}
            >
              {getCodeSnippet(selectedLanguage, modelName, optionsString)}
            </SyntaxHighlighter>
          </div>
          <Button
            onClick={() =>
              navigator.clipboard.writeText(
                getCodeSnippet(selectedLanguage, modelName, optionsString),
              )
            }
            className="w-full"
          >
            Copy code
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
