import { useEffect } from "react";
import { Input } from "@/components/ui/input";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { usePlayground } from "../../../../hooks/use-playground";

export function PaddingInput() {
  const { options, setOptions, isUploading, isGenerating } = usePlayground();

  useEffect(() => {
    console.log(`isUploading: `, isUploading);
  }, [isUploading]);

  useEffect(() => {
    console.log(`isGenerating: `, isGenerating);
  }, [isGenerating]);
  const isDisabled = isUploading || isGenerating;

  const handlePadChange = (index: number, value: string) => {
    console.log("handlePadChange: ", {
      index,
      value,
    });
    const newPads = [...options.pads];
    newPads[index] = parseInt(value) || 0;
    setOptions({ ...options, pads: newPads });
  };

  return (
    <HoverCard>
      <HoverCardTrigger asChild>
        <div
          className={`flex flex-col gap-4 ${isDisabled ? "opacity-50" : ""}`}
        >
          <label className={`block text-sm font-bold `}>padding</label>
          <div className="grid grid-cols-4 gap-2">
            {["Top", "Right", "Bottom", "Left"].map((side, index) => (
              <Input
                key={side}
                type="number"
                placeholder={side}
                className="w-full"
                value={options.pads[index]}
                onChange={(e) => handlePadChange(index, e.target.value)}
                disabled={isDisabled}
              />
            ))}
          </div>
        </div>
      </HoverCardTrigger>
      <HoverCardContent side="left" className="w-80">
        <div className="space-y-2">
          <h4 className="font-semibold">Padding</h4>
          <p className="text-sm">Adjust the spacing around the element</p>
        </div>
      </HoverCardContent>
    </HoverCard>
  );
}
