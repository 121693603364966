import { FC } from "react";
import ViewCodeButton from "./view-code-button";

const PlaygroundHeader: FC = () => {
  return (
    <div className="flex flex-col space-y-2 pb-4">
      <div className="flex justify-between items-center">
        <span className="text-xl">lipsync video</span>
        <ViewCodeButton />
      </div>
    </div>
  );
};

export default PlaygroundHeader;
