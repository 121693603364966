import { FC } from "react";
import { Button } from "@/components/ui/button";
import { Video, Mic, Square } from "lucide-react";

interface RecordButtonProps {
  isRecording: boolean;
  isPreparingToRecord: boolean;
  onStartRecording: () => void;
  onStopRecording: () => void;
  mediaType: "audio" | "video";
}

export const RecordButton: FC<RecordButtonProps> = ({
  isRecording,
  isPreparingToRecord,
  onStartRecording,
  onStopRecording,
  mediaType,
}) => {
  return (
    <Button
      variant="secondary"
      size="sm"
      onClick={isRecording ? onStopRecording : onStartRecording}
      disabled={isPreparingToRecord}
    >
      <div className="flex gap-2 items-center">
        {isRecording
          ? "Stop Recording"
          : isPreparingToRecord
            ? "Preparing..."
            : `Record ${mediaType}`}
        {isRecording ? (
          <Square className="h-4 w-4" />
        ) : mediaType === "audio" ? (
          <Mic className="h-4 w-4" />
        ) : (
          <Video className="h-4 w-4" />
        )}
      </div>
    </Button>
  );
};
