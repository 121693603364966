"use client";

import { useState, useEffect } from "react";
import { Button } from "@/components/ui";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogClose,
} from "@/components/ui/dialog";
import { PresetSelector } from "./preset-selector";
import PropertiesMenu from "./properties-menu";
import { usePlayground } from "../../hooks/use-playground";

export function PlaygroundSidebar() {
  const {
    activePreset,
    setActivePreset,
    createPreset,
    updatePreset,
    options,
    model,
    models,
    isUploading,
    isGenerating,
  } = usePlayground();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [presetName, setPresetName] = useState("");
  const [presetDescription, setPresetDescription] = useState<string | null>(
    null,
  );

  const isDisabled = isUploading || isGenerating;

  const handleSave = async () => {
    let updatedPreset;
    if (activePreset) {
      updatedPreset = await updatePreset(
        activePreset.id,
        presetName,
        presetDescription,
        model ? model.name : models[0].name,
        options,
      );
    } else if (model) {
      updatedPreset = await createPreset(
        presetName,
        presetDescription,
        model ? model.name : models[0].name,
        options,
      );
    }
    if (updatedPreset) {
      console.log(`updatedPreset: `, updatedPreset);
      setActivePreset(updatedPreset);
    }
    setDialogOpen(false);
  };

  const handleSaveAsNew = async () => {
    const newPreset = await createPreset(
      presetName,
      presetDescription,
      model ? model.name : models[0].name,
      options,
    );
    if (newPreset) {
      setActivePreset(newPreset);
    }
    setDialogOpen(false);
  };

  useEffect(() => {
    if (activePreset) {
      setPresetName(activePreset.name);
      setPresetDescription(activePreset.description || null);
    } else {
      setPresetName("");
      setPresetDescription("");
    }
  }, [activePreset, dialogOpen]);

  return (
    <div className="flex flex-col h-full w-full">
      <div className="flex justify-between items-center gap-2 mb-4">
        <PresetSelector />
        <Button
          variant="secondary"
          onClick={() => setDialogOpen(true)}
          disabled={isDisabled}
        >
          Save
        </Button>
      </div>
      <PropertiesMenu />

      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {activePreset ? "Update Preset" : "Save New Preset"}
            </DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <label htmlFor="name" className="text-right">
                Name
              </label>
              <input
                id="name"
                className="col-span-3"
                value={presetName}
                onChange={(e) => setPresetName(e.target.value)}
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <label htmlFor="description" className="text-right">
                Description
              </label>
              <textarea
                id="description"
                className="col-span-3"
                value={presetDescription || ""}
                onChange={(e) => setPresetDescription(e.target.value)}
              />
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="outline">Cancel</Button>
            </DialogClose>
            {activePreset && (
              <Button onClick={handleSaveAsNew} disabled={isDisabled}>
                Save as New
              </Button>
            )}
            <Button onClick={handleSave} disabled={isDisabled}>
              {activePreset ? "Update" : "Save"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
