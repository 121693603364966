export const goSnippet = `package main

import (
	"fmt"
	"strings"
	"net/http"
	"io/ioutil"
)

func main() {
	url := "https://api.sync.so/v2/generate"

	payload := strings.NewReader(\`{
  "model": "\${modelName}",
  "input": [
    {
      "type": "video",
      "url": "https://example.com/your-video.mp4"
    },
    {
      "type": "audio",
      "url": "https://example.com/your-audio.mp3"
    }
  ],
  "options": \${optionsString},
  "webhookUrl": "https://your-server.com/webhook"
}\`)

	req, _ := http.NewRequest("POST", url, payload)

	req.Header.Add("x-api-key", "<api-key>")
	req.Header.Add("Content-Type", "application/json")

	res, _ := http.DefaultClient.Do(req)

	defer res.Body.Close()
	body, _ := ioutil.ReadAll(res.Body)

	fmt.Println(res)
	fmt.Println(string(body))
}`;
