import { FC } from "react";

import { usePlayground } from "../../hooks/use-playground";
import ModelSelector from "./model-selector";
import CompareButton from "./compare-button";
import { VideoOutput } from "./outputs/video-output";
import VideoInput from "./inputs/video-input";
import { AudioOutput } from "./outputs/audio-output";
import AudioInput from "./inputs/audio-input";
import GenerateButton from "./generate-button";
import { PlaygroundDivider } from "./playground-divider";
import ClearButton from "./clear-button";

export const PlaygroundForm: FC = () => {
  const { models, model, setModel, videoSrc, generation } = usePlayground();

  const showOutputs = generation; // Show output video and audio if generation is present
  const generateButtonLabel = showOutputs ? "re-generate" : undefined;

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex justify-between items-center">
        <ModelSelector models={models} model={model} setModel={setModel} />
        <CompareButton />
      </div>
      <div className="space-y-2">
        {showOutputs ? <VideoOutput /> : <VideoInput showOptions={!videoSrc} />}
        <PlaygroundDivider />
        {showOutputs ? <AudioOutput /> : <AudioInput />}
      </div>
      <div className="flex gap-4 justify-end">
        <ClearButton />
        <GenerateButton label={generateButtonLabel} />
      </div>
    </div>
  );
};
