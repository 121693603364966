"use client";

import { FC, useState } from "react";
import { usePlayground } from "../../../../hooks/use-playground";
import { useAudioHandler } from "../../../../hooks/use-audio-handler";
import { UploadAnimation } from "../video-input/components/upload-animation";
import { AudioDisplay } from "./components/audio-display";
import { HostedUrlInput } from "../hosted-url-input";
import { RecordButton } from "../record-button";
import { Input } from "@/components/ui/input";

const DROP_OR_RECORD = "drop audio file or record";
const ACCEPTED_FORMATS = ".wav, .mp3 and .mpeg file formats accepted";

export const AudioInput: FC = () => {
  const { isUploading, isUploadComplete, audioSrc, setAudioSrc } =
    usePlayground();

  const { isRecording, startRecording, stopRecording, handleAudioUpload } =
    useAudioHandler();

  const [isPreparingToRecord, setIsPreparingToRecord] = useState(false);

  const showOptions = !audioSrc;

  const handleHostedUrlSubmit = (url: string) => setAudioSrc(url);
  const removeAudio = () => setAudioSrc("");

  const handleStartRecording = async () => {
    setIsPreparingToRecord(true);
    await startRecording();
    setIsPreparingToRecord(false);
  };

  const renderContent = () => {
    if (audioSrc) {
      return <AudioDisplay audioSrc={audioSrc} removeAudio={removeAudio} />;
    }

    return (
      <div className="flex justify-between bg-background border border-dashed rounded h-20">
        <div className="flex flex-grow relative items-center">
          <Input
            type="file"
            id="audio-upload"
            accept=".wav,.mp3,.mpeg"
            onChange={handleAudioUpload}
            className="flex h-full cursor-pointer opacity-0 z-10"
            disabled={isUploading}
          />
          <div className="absolute px-4">
            <AudioInputMessage />
          </div>
        </div>
        {showOptions && (
          <div className="flex items-center gap-4 z-10 p-4">
            <div className="flex gap-2 w-full justify-start">
              <HostedUrlInput onSubmit={handleHostedUrlSubmit} />
              <RecordButton
                isRecording={isRecording}
                isPreparingToRecord={isPreparingToRecord}
                onStartRecording={handleStartRecording}
                onStopRecording={stopRecording}
                mediaType="audio"
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="relative">
      <div className="flex-grow">{renderContent()}</div>
    </div>
  );
};

const AudioInputMessage = () => {
  return (
    <div className="flex flex-col w-fit text-left">
      <p className="text-sm font-semibold whitespace-nowrap">
        {DROP_OR_RECORD}
      </p>
      <p className="text-xs text-gray-400 whitespace-nowrap">
        {ACCEPTED_FORMATS}
      </p>
    </div>
  );
};

export default AudioInput;
