import { ChangeEvent, useRef, useState } from "react";
import { usePlayground } from "../hooks/use-playground";

// Custom hook for video handling
export const useVideoHandler = () => {
  const { videoSrc, setVideoSrc } = usePlayground();
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const streamRef = useRef<MediaStream | null>(null);
  const [previewStream, setPreviewStream] = useState<MediaStream | null>(null);

  const handleVideoUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const newVideoSrc = URL.createObjectURL(file);
      setVideoSrc(newVideoSrc);
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      streamRef.current = stream;
      setPreviewStream(stream);
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: "video/mp4",
      });
      const chunks: Blob[] = [];

      mediaRecorderRef.current.ondataavailable = (e) => chunks.push(e.data);
      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunks, { type: "video/mp4" });
        const newVideoSrc = URL.createObjectURL(blob);
        setVideoSrc(newVideoSrc);
      };

      setIsRecording(true);
      mediaRecorderRef.current.start();
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);

      // Stop all tracks of the stream
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }

      // Clear the preview stream
      setPreviewStream(null);
    }
  };

  return {
    videoSrc,
    isRecording,
    handleVideoUpload,
    startRecording,
    stopRecording,
    videoRef,
    previewStream,
  };
};
