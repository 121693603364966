export const pythonSnippet = `import requests

url = "https://api.sync.so/v2/generate"

payload = {
    "model": "\${modelName}",
    "input": [
        {
            "type": "video",
            "url": "https://example.com/your-video.mp4"
        },
        {
            "type": "audio",
            "url": "https://example.com/your-audio.mp3"
        }
    ],
    "options": \${optionsString},
    "webhookUrl": "https://your-server.com/webhook"
}
headers = {
    "x-api-key": "<api-key>",
    "Content-Type": "application/json"
}

response = requests.request("POST", url, json=payload, headers=headers)

print(response.text)`;
