import { useCallback } from "react";
import { handleSubmit } from "@/utils/playground-utils";
import uploadFile from "@/lib/api/uploadFile";
import generateRequest from "@/lib/api/generateRequest";
import { usePlaygroundState } from "./use-playground-state";
import { usePlaygroundQueries } from "./use-playground-queries";

// Custom hook to manage playground callbacks
export function usePlaygroundCallbacks(
  state: ReturnType<typeof usePlaygroundState>,
  queries: ReturnType<typeof usePlaygroundQueries>,
) {
  // Create a memoized callback for handling form submission
  const handleSubmitCallback = useCallback(() => {
    handleSubmit({
      // Pass all necessary state and query values to the handleSubmit function
      videoSrc: state.videoSrc,
      audioSrc: state.audioSrc,
      model: state.model,
      isCompareMode: state.isCompareMode,
      comparisonModel: state.comparisonModel,
      generation: state.generation,
      comparisonGeneration: state.comparisonGeneration,
      options: state.options,
      accessToken: queries.accessToken ?? "",
      organization: queries.organization,
      // Pass state update functions
      setError: state.setError,
      setIsGenerating: state.setIsGenerating,
      setIsUploading: state.setIsUploading,
      setIsUploadComplete: state.setIsUploadComplete,
      setGeneration: state.setGeneration,
      setComparisonGeneration: state.setComparisonGeneration,
      // Pass API functions
      uploadFile,
      generateRequest,
      fetchGeneration: queries.fetchGeneration,
    });
  }, [
    state.videoSrc,
    state.audioSrc,
    state.model,
    state.isCompareMode,
    state.comparisonModel,
    state.generation,
    state.comparisonGeneration,
    state.options,
    state.setError,
    state.setIsGenerating,
    state.setIsUploading,
    state.setIsUploadComplete,
    state.setGeneration,
    state.setComparisonGeneration,
    queries.accessToken,
    queries.organization,
    queries.fetchGeneration,
  ]);

  // Return an object with the memoized handleSubmit callback
  return {
    handleSubmit: handleSubmitCallback,
  };
}
