import React, { FC, RefObject } from "react";
import { Upload } from "lucide-react";
import { Input } from "@/components/ui/input";

interface RecordingPreviewProps {
  localVideoRef: RefObject<HTMLVideoElement>;
  isRecording: boolean;
  isPreparingToRecord: boolean;
  isUploading: boolean;
  handleVideoUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RecordingPreview: FC<RecordingPreviewProps> = ({
  localVideoRef,
  isRecording,
  isPreparingToRecord,
  isUploading,
  handleVideoUpload,
}) => {
  return (
    <div className="relative w-full h-full flex items-center justify-center">
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="w-full h-full aspect-video">
          <video
            ref={localVideoRef}
            autoPlay
            playsInline
            muted
            className={`w-full h-full object-cover ${
              isRecording || isPreparingToRecord ? "" : "hidden"
            }`}
          />
          {!isRecording && !isPreparingToRecord && (
            <>
              <Input
                type="file"
                id="video-upload"
                accept=".mp4,.mov,.avi"
                onChange={handleVideoUpload}
                className="absolute inset-0 flex h-full cursor-pointer opacity-0 z-10"
                disabled={isUploading}
              />
              <div className="flex flex-col items-center gap-4 text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <Upload size={63} />
                <div className="flex flex-col items-center">
                  <p className="text-xl font-medium">drag and drop to upload</p>
                  <p className="">
                    or <span className="text-primary">browse</span>
                  </p>
                </div>
              </div>
            </>
          )}
          {isPreparingToRecord && (
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <p className="text-white">Preparing to record...</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
