"use client";

import * as React from "react";
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import { PopoverProps } from "@radix-ui/react-popover";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui";
import { usePlayground } from "../../hooks/use-playground";

interface PresetSelectorProps extends PopoverProps {}

export function PresetSelector({ ...props }: PresetSelectorProps) {
  const [open, setOpen] = React.useState(false);

  const {
    activePreset,
    setActivePreset,
    generationPresets,
    presetsLoading,
    presetsError,
    isUploading,
    isGenerating,
  } = usePlayground();

  const isDisabled = isUploading || isGenerating;

  if (presetsLoading) {
    return <div>Loading presets...</div>;
  }

  if (presetsError) {
    return <div>Error loading presets: {presetsError.message}</div>;
  }

  return (
    <Popover
      open={open && !isDisabled}
      onOpenChange={(newOpen) => !isDisabled && setOpen(newOpen)}
      {...props}
    >
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-label="presets"
          aria-expanded={open}
          className="flex-1 w-full"
          disabled={isDisabled}
        >
          <div className="flex w-full justify-between items-center">
            {activePreset ? activePreset.name : "presets"}
            <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[300px] p-0">
        <Command>
          <CommandInput placeholder="Search presets..." disabled={isDisabled} />
          <CommandList>
            <CommandEmpty>No presets found.</CommandEmpty>
            <CommandGroup heading="Presets">
              {generationPresets.map((preset) => (
                <CommandItem
                  key={preset.id}
                  onSelect={() => {
                    if (!isDisabled) {
                      setActivePreset(preset);
                      setOpen(false);
                      // TODO: Apply the selected preset to the playground
                    }
                  }}
                  disabled={isDisabled}
                >
                  {preset.name}
                  <CheckIcon
                    className={cn(
                      "ml-auto h-4 w-4",
                      activePreset?.id === preset.id
                        ? "opacity-100"
                        : "opacity-0",
                    )}
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
