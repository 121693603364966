"use client";

import { ReactNode } from "react";
import { PlaygroundContext } from "@/contexts/playground-context";
import { usePlaygroundState } from "@/components/playground/hooks/use-playground-state";
import { usePlaygroundQueries } from "@/components/playground/hooks/use-playground-queries";
import { usePlaygroundEffects } from "@/components/playground/hooks/use-playground-effects";
import { usePlaygroundCallbacks } from "@/components/playground/hooks/use-playground-callbacks";

export function PlaygroundProvider({ children }: { children: ReactNode }) {
  const state = usePlaygroundState();
  const queries = usePlaygroundQueries(state);
  const effects = usePlaygroundEffects(state, queries);
  const callbacks = usePlaygroundCallbacks(state, queries);

  const contextValue = {
    ...state,
    ...queries,
    ...effects,
    ...callbacks,
  };

  return (
    <PlaygroundContext.Provider value={contextValue}>
      {children}
    </PlaygroundContext.Provider>
  );
}
