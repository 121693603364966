import { usePlayground } from "../../../../hooks/use-playground";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";

export function QualitySelector() {
  const { options, setOptions, isUploading, isGenerating } = usePlayground();

  const handleQualityChange = (value: string) => {
    setOptions({ ...options, quality: value });
  };

  const isDisabled = isUploading || isGenerating;

  return (
    <HoverCard>
      <HoverCardTrigger asChild>
        <div className="flex flex-col gap-4">
          <label
            className={`block text-sm font-bold ${isDisabled ? "opacity-50" : ""}`}
          >
            quality
          </label>
          <Select
            value={options.quality}
            onValueChange={handleQualityChange}
            disabled={isDisabled}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select quality" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="low">low</SelectItem>
              <SelectItem value="medium">medium</SelectItem>
              <SelectItem value="high">high</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </HoverCardTrigger>
      <HoverCardContent side="left" className="w-80">
        <div className="space-y-2">
          <h4 className="font-semibold">Quality</h4>
          <p className="text-sm">Set the quality level of the output</p>
        </div>
      </HoverCardContent>
    </HoverCard>
  );
}
