"use client";

import { FC } from "react";

import { usePlayground } from "@/components/playground/hooks/use-playground";

import { ErrorMessage } from "./components/error-message";
import PlaygroundHeader from "./components/header/playground-header";
import { PlaygroundForm } from "./components/form";
import { PlaygroundCompareForm } from "./components/form/playground-compare-form";
import { PlaygroundSidebar } from "./components/sidebar/playground-sidebar";

const Playground: FC = () => {
  const { isCompareMode } = usePlayground();

  return (
    <>
      <ErrorMessage />
      <div className="flex flex-col lg:flex-row min-h-full w-full">
        <div className="flex flex-1 flex-col p-6 gap-2 basis-3/4 bg-secondary-background">
          <PlaygroundHeader />
          {isCompareMode ? <PlaygroundCompareForm /> : <PlaygroundForm />}
        </div>
        {!isCompareMode && (
          <div className="hidden lg:flex lg:basis-1/4 flex-col p-6 border-l bg-background">
            <PlaygroundSidebar />
          </div>
        )}
      </div>
    </>
  );
};

export default Playground;
