"use client";

import { FC } from "react";
import { usePlayground } from "../../../hooks/use-playground";

export const AudioOutput: FC = () => {
  const { generation } = usePlayground();

  if (!generation) return null;

  const audioInput = generation.inputs.find(
    (input: { url: string; type: string }) => input.type === "audio",
  );

  return (
    <div className="flex-grow audio-wrapper bg-background rounded">
      <audio src={audioInput.url} controls className="w-full">
        Your browser does not support the audio tag.
      </audio>
    </div>
  );
};
