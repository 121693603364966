import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { usePlayground } from "../../../../hooks/use-playground";
import { cn } from "@/lib/utils";

export function OutputFormatSelector() {
  const { options, setOptions, isUploading, isGenerating } = usePlayground();

  const handleFormatChange = (value: string) => {
    setOptions({ ...options, output_format: value });
  };

  const isDisabled = isUploading || isGenerating;

  return (
    <HoverCard>
      <HoverCardTrigger asChild>
        <div className="flex flex-col gap-4">
          <label
            className={cn(
              "block text-sm font-bold",
              isDisabled && "opacity-50",
            )}
          >
            output format
          </label>
          <Select
            value={options.output_format}
            onValueChange={handleFormatChange}
            disabled={isDisabled}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select format" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="mp4">mp4</SelectItem>
              <SelectItem value="mov">mov</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </HoverCardTrigger>
      <HoverCardContent side="left" className="w-80">
        <div className="space-y-2">
          <h4 className="font-semibold">Output Format</h4>
          <p className="text-sm">Choose the file format for the output</p>
        </div>
      </HoverCardContent>
    </HoverCard>
  );
}
